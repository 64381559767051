const openNewWindow = (url, params) => {
    try {
        console.log("openNewWindow", url, params)
        // Creation FORM
        const form = document.createElement('form')
        // Gestion URL
        form.setAttribute('action', url)
        form.setAttribute('method', 'POST')
        form.setAttribute('target', '_blank')
        // Ajout des autres variables dans FORM
        for (let inName in params) {
            const inValue = params[inName]
            const inputParam = document.createElement('input')
            inputParam.setAttribute('type', 'hidden')
            inputParam.setAttribute('name', inName)
            inputParam.setAttribute('value', inValue)
            form.appendChild(inputParam)
        }
        // Ajout bouton
        const inputSubmit = document.createElement('input')
        inputSubmit.setAttribute('type', 'submit')
        // Ajout du FORM + GO
        document.body.appendChild(form)
        form.style.display = 'none'
        form.submit()
        document.body.removeChild(form)
    } catch (error) { }
    return;
}

export {
    openNewWindow
}