import React from 'react'
import '../scss/Loader.scss'

const Loader = ({ size = 80, color = '#eceff1' }) => {
  return (
    <div className='loader-circle' style={{ width: size + 'px', height: size + 'px' }} >
      <span style={{ width: size + 'px', height: size + 'px' }}></span>
      <div
        className='loader-circle-lvl2'
        style={{
          width: size - (size * 0.05) + 'px',
          height: size - (size * 0.05) + 'px',
          top: (size * 0.05) / 2 + 'px',
          left: (size * 0.05) / 2 + 'px',
          backgroundColor: color
        }}
      ></div>
    </div>
  )
}

export default Loader
