import { VAR_LOCALSTORAGE_TOKEN, VAR_LOCALSTORAGE_LOADING } from '../variables/localStorage'

const userStateDefault = {
    token: null,
}

// Gestion Token
const _getToken = () => {
    return localStorage.getItem(VAR_LOCALSTORAGE_TOKEN)
}
const _setToken = token => {
    return localStorage.setItem(VAR_LOCALSTORAGE_TOKEN, token)
}
const _deleteToken = () => {
    return localStorage.removeItem(VAR_LOCALSTORAGE_TOKEN)
}
// Gestion Appload
const _getAppload = () => {
    try {
        return JSON.parse(localStorage.getItem(VAR_LOCALSTORAGE_LOADING))
    } catch (error) { }
    return null
}
const _setAppload = appload => {
    return localStorage.setItem(VAR_LOCALSTORAGE_LOADING, appload)
}
const _deleteAppload = () => {
    return localStorage.removeItem(VAR_LOCALSTORAGE_LOADING)
}

const reducer = (state = { ...userStateDefault, token: _getToken(), appload: _getAppload() }, action) => {
    switch (action.type) {
        case 'SET_USER_TOKEN':
            _setToken(action.payload)
            return {
                ...state,
                token: action.payload
            }

        case 'SET_USER_APPLOAD':
            _setAppload(action.payload)
            return {
                ...state,
                appload: action.payload
            }

        case 'SET_USER_ACCESS':
            return {
                ...state,
                access: action.payload
            }

        case "SET_USER_ALERT":
            return {
                ...state,
                alert: action.payload
            }

        case 'SET_USER_INFOS':
            return {
                ...state,
                infos: action.playload
            }

        case 'LOGOUT':
            _deleteToken()
            _deleteAppload()
            return { ...userStateDefault }

        default:
            return state
    }
}

export default reducer
