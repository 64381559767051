import { useState, useEffect } from 'react'
import './ConfirmCgu.scss'
import { useTranslation } from 'react-i18next'
import { formatText, extractParagraph } from '../../../helpers'
import {
    Button,
    Checkbox,
    Form,
    Input,
    //AutoComplete,Cascader,Col,InputNumber,Row,Select,
} from 'antd'
import InfosAndQuit from '../InfosAndQuit/InfosAndQuit'

import axios from 'axios'
import { connect } from 'react-redux'

const ConfirmCgu = (props) => {
    const {
        token = null,
        callBack = null,
        detail = null,
        changeModalWidth = null
    } = props

    const { t } = useTranslation()

    const [errorMessage, setErrorMessage] = useState(null)
    const [formPending, setFormPending] = useState(false)
    const [formDone, setFormDone] = useState(false)
    const [formCancel, setFormCancel] = useState(false)
    const [displayCancel, setDisplayCancel] = useState(true)

    const [questionCgu, setQuestionCgu] = useState(null)
    useEffect(() => {
        var question
        try {
            question = detail.question[0]
            setQuestionCgu(question)
        } catch (error) { }
    }, [detail])

    const getReponseIdAccepte = () => {
        var reponseId = null
        try {
            const reponse = questionCgu.proposed_answers[0]
            reponseId = reponse.answer_id
        } catch (error) { }
        return reponseId
    }

    // Integration de ANTD
    const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 }, },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, },
    };
    const [form] = Form.useForm();
    const onFinish = (values) => {
        // Un seul à la fois
        if (formPending) {
            console.log('Received values but SKIP');
            return;
        }
        // Tentative de MAJ
        console.log('Received values of form: ', values);
        const confirmCguChecked = !(!(values.confirm_cgu))
        const confirmCguQuestionId = values.questionId
        const confirmCguReponseId = values.reponseId
        if (confirmCguChecked) {
            accepteCgu(confirmCguQuestionId, confirmCguReponseId)
        }

    };

    const signalToken = axios.CancelToken.source()
    const accepteCgu = (qId, rId) => {
        setFormPending(true)
        try {
            const data = { question: qId, answer: rId }
            const url = process.env.REACT_APP_URL_GD6D
            axios.post(url + '?r=apiBiz/postAcceptCgu', data, { headers: { 'Authorization': 'Bearer ' + token }, CancelToken: signalToken.token })
                .then(res => {
                    const output = res.data
                    console.log("accepteCgu", data, output)
                    setFormPending(false)
                    setFormDone(true)
                })
                .catch(err => {
                    console.warn(err.data)
                    setErrorMessage('error_0002') // ('Identifiant ou mot de passe incorrect')
                    setFormPending(false)
                })
        } catch (err) {
            console.warn('Erreur lors de la connexion', err)
            setErrorMessage('error_0003') // ('Erreur de communication avec la base de données')
            setFormPending(false)
        }
    }
    const refuseCgu = () => {
        if (changeModalWidth) {
            changeModalWidth(400)
        }
        setFormCancel(true)
    }

    if (formCancel) {
        return (
            <InfosAndQuit logOut={true} >
            </InfosAndQuit>
        )
    }
    if (formDone) {

        return (
            <InfosAndQuit delay={2} callBack={callBack} >
                <p>{formatText(t('modal_changedpassword'))}.</p>
            </InfosAndQuit>
        )
    }
    const getContenuChildren = () => {
        var text = questionCgu.question_text
        return extractParagraph(text).map((p, i) => {
            return (
                <p key={i} dangerouslySetInnerHTML={{ __html: p }} />
            )
        });
    }

    if (!questionCgu) {
        return (
            <p>{formatText(t('chargement'))}</p>
        )
    }

    const questionId = questionCgu.question_id
    const reponseId = getReponseIdAccepte()
    const hiddenField = true
    return (
        <>
            <p>{t('modal_cgu')}</p>
            <div
                style={{ minHeight: 175, maxHeight: 225, fontSize: "smaller", border: "1px solid #CCC", overflowY: 'scroll', padding: 20 }}>
                {getContenuChildren(questionCgu)}
            </div>

            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    reponseId: reponseId,
                    questionId: questionId,
                }}
                scrollToFirstError
            >
                <Form.Item
                    name="confirm_cgu"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error(t('please_acceptToContinue'))),
                        },
                    ]}
                >
                    <Checkbox>{(t('modal_accepteCgu'))}</Checkbox>
                </Form.Item>

                <Form.Item name="reponseId" label="Id réponse" hidden={hiddenField}>
                    <Input type="text" />
                </Form.Item>
                <Form.Item name="questionId" label="Id question" hidden={hiddenField}>
                    <Input type="text" />
                </Form.Item>

                <Form.Item hidden={!(displayCancel)} style={{ float: "right" }}>
                    <Button onClick={refuseCgu}
                    >
                        {formatText(t('annuler'))}
                    </Button>
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit"
                        loading={formPending}
                    >
                        {formatText(t(formPending ? 'enCours' : 'enregistrer'))}
                    </Button>
                </Form.Item>

                <div className={`error-message ${errorMessage && errorMessage.length > 0 ? 'error-message--visible' : 'error-message--unvisible'}`}>
                    {formatText(t(errorMessage))}
                </div>
            </Form>

        </>
    )

}

const mapStateToProps = (state) => {
    return {
        token: state.user.token || null,
    }
}

export default connect(mapStateToProps)(ConfirmCgu)
