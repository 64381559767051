import { useState, useEffect } from 'react'
import './ActionLoading.scss'
import { useTranslation } from 'react-i18next'
import { formatText } from '../../helpers'
import { Modal } from 'antd'
import { DURATION_RELOAD_APP } from '../../variables/params'
// Actions spéciales
import InfosAndQuit from '../Login_sub/InfosAndQuit/InfosAndQuit'
import ConfirmPassword from '../Login_sub/ConfirmPassword/ConfirmPassword'
import ConfirmCgu from '../Login_sub/ConfirmCgu/ConfirmCgu'

const ActionLoading = (props) => {
    const {
        alert = null,
    } = props

    const { t } = useTranslation()

    console.log("ActionLoading", props, alert)
    const [open, setOpen] = useState(true)
    const [width, setWidth] = useState(500)
    const [closable, setClosable] = useState(true)
    const [title, setTitle] = useState("info")
    const [child, setChild] = useState((
        <div>...</div>
    ))

    const validPassword = () => {
        console.log("ActionLoading", "PWD validé !!");
    }
    const validCgu = () => {
        console.log("ActionLoading", "CGU validé !!");
    }

    // Mise à jour du contenu Modal
    useEffect(() => {
        const {
            motif = null,
            detail = null,
        } = alert
        console.log("useEffect", detail, props)
        if (motif === "NOT_ACTIVE") {
            //console.log("Deconnexion et Rechargement")
            const delay = 1 * DURATION_RELOAD_APP || 0
            setClosable(false)
            setTitle('info')
            setChild(
                <InfosAndQuit delay={delay} logOut={true} >
                    <p>{formatText(t('modal_notactive'))}.</p>
                </InfosAndQuit>
            )
        }
        else if (motif === "EMPTY_PASSWORD") {
            //console.log("Chargement de ConfirmPassword")
            setWidth(750)
            setClosable(false)
            setChild((
                <ConfirmPassword
                    callback={validPassword}
                    changeModalWidth={setWidth}
                />
            ))
        }
        else if (motif === "CGU_NOT_ACCEPTED") {
            //console.log("Chargement de ConfirmCgu")
            setWidth(1000)
            setClosable(false)
            setChild((
                <ConfirmCgu
                    callback={validCgu}
                    detail={detail}
                    changeModalWidth={setWidth}
                />
            ))
        }
    }, [alert, props, t])

    // Affichage
    if (alert) {
        return (
            <Modal
                centered
                width={width}
                title={formatText(t(title))}
                open={open}
                //onOk={() => setOpen(false)}
                //onCancel={() => setOpen(false)}
                footer={null}
                maskClosable={closable}
                closable={closable}
            >
                {child}
                {false && <p style={{ backgroundColor: 'skyblue', padding: 10, marginTop: 20 }}>{JSON.stringify(alert)}</p>}
            </Modal>
        )
    }
    return null
}

export default ActionLoading
