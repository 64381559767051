const URLAPPGD6D = {
    platform: process.env.REACT_APP_URL_PLATFORM,
    manaAcc: process.env.REACT_APP_URL_MANAGEMENT_ACCOMPAGNEMENT,
    manaMob: process.env.REACT_APP_URL_MANAGEMENT_MOBILISATION,
    opeAcc: process.env.REACT_APP_URL_COACHING_ACCOMPAGNEMENT,
    opeMob: process.env.REACT_APP_URL_COACHING_MOBILISATION,
    dashboard: process.env.REACT_APP_URL_DASHBOARD,
    admin: process.env.REACT_APP_URL_ADMINISTRATION,
}

export default URLAPPGD6D