import { useState, useEffect } from 'react'
import './InfosAndQuit.scss'
import { useTranslation } from 'react-i18next'
import { formatText } from '../../../helpers'
import { SyncOutlined } from '@ant-design/icons'
import { VAR_LOCALSTORAGE_TOKEN } from '../../../variables/localStorage'
import { DURATION_RELOAD_APP } from '../../../variables/params'

const InfosAndQuit = (props) => {
    const {
        delay = DURATION_RELOAD_APP,
        callBack = null,
        logOut = false,
    } = props

    const { t } = useTranslation()
    const [delayCurrent, setDelayCurrent] = useState(delay)
    const [timeCurrent, setTimeCurrent] = useState(Date.now())

    const decCount = () => {
        const diff = (Date.now() - timeCurrent) / 1000;
        const newDelayCurrent = Math.max(0, parseInt(delay - diff + 0.2))
        //console.log("decCount", diff, newDelayCurrent)
        setDelayCurrent(newDelayCurrent)
    }
    const closeAndReload = (v) => {
        //console.log("closeAndReload", v)
        window.location.reload()
    }
    const reinitAndReload = (v) => {
        //console.log("reinitAndReload", v)
        localStorage.removeItem(VAR_LOCALSTORAGE_TOKEN)
        window.location.reload()
    }

    const callQuit = () => {
        if (callBack) {
            console.log("_CALL_", "callBack", Date.now())
            callBack()
        }
        if (logOut) {
            console.log("_CALL_", "reinitAndReload", Date.now())
            reinitAndReload()
        } else {
            console.log("_CALL_", "closeAndReload", Date.now())
            closeAndReload()
        }
    }

    useEffect(() => {
        console.log("_CALL_", "useEffect", Date.now())
        if (delay > 0) {
            setTimeout(callQuit, delay * 1000)
        } else {
            callQuit()
        }
        setInterval(decCount, 1000)
    }, [delay, logOut])

    return (
        <>
            {props.children}
            {false && <SyncOutlined spin style={{ fontSize: 25, float: "right" }} />}
            {
                logOut &&
                <p>
                    {formatText(delayCurrent > 0 ? t('vous_serez_deconnecte_delai', { delay: delayCurrent }) : t('vous_serez_deconnecte'))}.
                </p>
            }
            {
                !(logOut) &&
                <p>
                    {formatText(delayCurrent > 0 ? t('votre_application_reconnecte_delai', { delay: delayCurrent }) : t('votre_application_reconnecte'))}.
                </p>
            }
        </>
    )
}

export default InfosAndQuit
