import i18n from 'i18next'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { VAR_LOCALSTORAGE_LANGUAGE } from './variables/localStorage'

// JSON de traduction
import { en, fr, es } from './content/_languageFiles'

// Config Antd (à commenter si non chargé)
/*
import antdLocale_frFR from 'antd/es/locale/fr_FR';
import antdLocale_enGB from 'antd/es/locale/en_GB';
import antdLocale_esES from 'antd/es/locale/es_ES';
*/

/*
  Hint: Commenter "fallbackLng:'fr'" pour forcer le système à lister les éléments non traduits
*/

// 
const resources = {
  en,
  fr,
  es
}

// 
i18n.__giveMomentLocale = () => {
  const locales = {
    "fr": "fr",
    "en": "en-gb",
    "es": "es"
  }
  if (i18n.language in locales) {
    return locales[i18n.language];
  }
  return "fr";
}

// Config Antd (à commenter si non chargé)
/*
i18n.__giveAntdLocale = () => {
  // Internationalization de Ant Design : https://ant.design/docs/react/i18n
  const locales = {
    "fr" : antdLocale_frFR,
    "en" : antdLocale_enGB,
    "es" : antdLocale_esES
  }
  if( i18n.language in locales  ) {
    return locales[ i18n.language ];
  }
  return antdLocale_frFR;
}
*/


// Configuration
i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem(VAR_LOCALSTORAGE_LANGUAGE),
    fallbackLng: 'fr',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false
    },
    react: {
      useSuspense: false
    },
    saveMissing: true,
    ns: ["gd6d-ecosysteme", "common", "messages", "translation"],
    defaultNS: "gd6d-ecosysteme",
    fallbackNS: ["common", "messages", "translation"]
  })

export default i18n
