import './App.scss';
import { useEffect } from 'react'
import { connect } from 'react-redux'
import AppContent from './components/AppContent/AppContent'
import Login from './components/Login/Login'
import { logOut } from './actions/userActions'
import { AlertTwoTone, WarningTwoTone } from '@ant-design/icons'
import { notification } from 'antd'
import { isMobile } from 'react-device-detect'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { formatText } from './helpers'
import { openNewWindow } from './functions/redirections'
import URLAPPGD6D from './variables/URLAPPGD6D'

const App = (props) => {
  const { user, logOut } = props
  const isConnected = user.infos !== undefined
  const loadingInfos = user.appload || null
  const { t } = useTranslation()

  const handleClose = () => {
    //console.log('CLOSE Notif.')
  }
  const handleClick = () => {
    //console.log('CLICK Notif.')
  }

  const getInfos = (loadingInfos) => {
    const { action = null, code = null, origin } = loadingInfos
    const { id = null, mail = null } = origin
    var message = "", description = "", icon = null
    message = t('action_prise_en_compte')
    var text = t(action + '_' + code, { mail });
    description = text == (action + '_' + code) ? t('reconnectez_vous') : formatText(text)
    if (action == 'confirm_mail') {
      description = <span>
        {description}.<br />{formatText(t('email'))} : <em>{mail}</em>
      </span>
    }
    return { message, description, icon }
  }

  useEffect(() => {
    console.log('loadingInfos', loadingInfos)
    if (!isMobile && loadingInfos) {
      const infos = getInfos(loadingInfos)
      notification.open({
        message: infos && infos.message ? infos.message : "",
        description: <span>
          {
            infos && infos.description
              ? infos.description
              :
              <span>Références :
                <b>{loadingInfos.action}</b> #{loadingInfos.code}<br />
                Plus d'informations : <em>A venir</em>
              </span>
          }
        </span>,
        placement: isMobile ? 'topLeft' : 'bottomRight',
        icon: infos && infos.icon ? infos.icon : <WarningTwoTone twoToneColor='#FF0000' />,
        onClick: handleClick,
        onClose: handleClose,
        duration: 0
      })
    }
  }, [loadingInfos])

  const goAdmin = () => {
    const urlAdmin = URLAPPGD6D.admin
    openNewWindow(urlAdmin, { token: user.token, lang: i18n.language })
  }

  return (
    <div className="App">
      {isConnected && <AppContent user={user} logOut={logOut} goAdmin={goAdmin} />}
      {!isConnected && <Login />}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}
const dispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut())
  }
}

export default connect(mapStateToProps, dispatchToProps)(App);
