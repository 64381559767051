import { useState, useEffect, useRef, useCallback } from 'react'
import './ConfirmPassword.scss'
import { useTranslation } from 'react-i18next'
import { formatText } from '../../../helpers'
import {
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
} from 'antd'
import InfosAndQuit from '../InfosAndQuit/InfosAndQuit'

import axios from 'axios'
import { connect } from 'react-redux'

const ConfirmPassword = (props) => {
    const {
        token = null,
        login = null,
        callBack = null,
        changeModalWidth = null,
    } = props

    const { t } = useTranslation()

    const [errorMessage, setErrorMessage] = useState(null)
    const [formPending, setFormPending] = useState(false)
    const [formDone, setFormDone] = useState(false)

    // Integration de ANTD
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const [form] = Form.useForm();
    const onFinish = (values) => {
        // Un seul à la fois
        if (formPending) {
            console.log('Received values but SKIP');
            return;
        }
        // Tentative de MAJ
        console.log('Received values of form: ', values);
        setFormPending(true)
        // Ajouter ICI le lien vers API
        updatePassword(values.login, values.password)
    };

    const signalToken = axios.CancelToken.source()

    const updatePassword = (login, password) => {
        try {
            const data = { login, password }
            const url = process.env.REACT_APP_URL_GD6D
            axios.post(url + '?r=apiBiz/postUpdateLog', data, { headers: { 'Authorization': 'Bearer ' + token }, CancelToken: signalToken.token })
                .then(res => {
                    const output = res.data
                    console.log("updatePassword", data, output)
                    setFormPending(false)
                    setFormDone(true)
                })
                .catch(err => {
                    console.warn(err.data)
                    setErrorMessage('error_0002') // ('Identifiant ou mot de passe incorrect')
                    setFormPending(false)
                })
        } catch (err) {
            console.warn('Erreur lors de la connexion', err)
            setErrorMessage('error_0003') // ('Erreur de communication avec la base de données')
            setFormPending(false)
        }
    }

    if (formDone) {
        if (changeModalWidth) {
            changeModalWidth(400)
        }
        return (
            <InfosAndQuit delay={2} callBack={callBack} >
                <p>{formatText(t('modal_changedpassword'))}.</p>
            </InfosAndQuit>
        )
    }
    const initPwd = ""
    return (
        <>
            <p>{formatText(t('modal_emptypassword'))} :</p>
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    login,
                    password: initPwd,
                    confirm: initPwd,
                }}
                scrollToFirstError
            >
                <Form.Item
                    name="login"
                    label={formatText(t('identifiant'))}
                    hasFeedback
                    validateStatus="success"
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={formatText(t('motDePasse'))}
                    rules={[
                        {
                            required: true,
                            message: formatText(t('please_inputPassword')),
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label={formatText(t('motDePasse_confirmation'))}
                    dependencies={['password']}
                    rules={[
                        {
                            //required: true,
                            //message: formatText(t('please_confirmPassword')),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    setErrorMessage(null)
                                    return Promise.resolve();
                                }
                                setErrorMessage(formatText(t('please_noMatchingPassword')))
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit"
                        loading={formPending}
                    >
                        {formatText(t(formPending ? 'enCours' : 'enregistrer'))}
                    </Button>
                </Form.Item>

                <div className={`error-message ${errorMessage && errorMessage.length > 0 ? 'error-message--visible' : 'error-message--unvisible'}`}>
                    {formatText(t(errorMessage))}
                </div>
            </Form>

        </>
    )

}

const mapStateToProps = (state) => {
    return {
        login: state.user.infos.login || null,
        token: state.user.token || null,
    }
}

export default connect(mapStateToProps)(ConfirmPassword)