
import json_FR from './fr.json'
import json_EN from './en.json'
import json_ES from './es.json'



export const fr = json_FR
export const en = json_EN
export const es = json_ES
