import './Ecosystem.scss'
import URLAPPGD6D from '../../variables/URLAPPGD6D'
import {
    Gd6DLogoPlatform,
    Gd6DLogoOpeMob,
    Gd6DLogoOpeAccLogo,
    Gd6DLogoManagementMob,
    Gd6DLogoManagementAcc,
    Gd6DLogoDashboard
} from '../SvgComponent'
import i18n from "i18next"
import { useTranslation } from 'react-i18next'
import { formatText } from '../../helpers'

const Ecosystem = ({ token, access }) => {
    const size = 500
    const center = size / 2
    const outerCircleRadius = (size / 2) - (size * 0.01)
    const innerCircleThickness = ((size) - (size * 0.01)) - (size * 0.1)
    const innerCircleRadius = innerCircleThickness / 2
    const circleRadius = size * 0.065
    const textCircleRadius = innerCircleRadius + size * 0.01
    const textCircleRadius2 = (innerCircleRadius + 7) + size * 0.01
    const ElPosRadius = innerCircleRadius * 0.5 + circleRadius * 0.75
    const buttonWidth = size * 0.14
    const buttonHeight = size * 0.04
    const animDur = 4
    const getPosX = (angle, r = innerCircleRadius) => Math.cos(angle) * r
    const getPosY = (angle, r = innerCircleRadius) => Math.sin(angle) * r
    const style = {
        text: {
            stroke: 'none',
            fill: '#90a4ae',
            fontSize: '10px',
            fontWeight: 500,
            textAnchor: 'middle',
            textTransform: 'uppercase'
        }
    }
    const { t } = useTranslation()

    const SVGButton = ({ posX, posY, url, isDisabled }) => {
        const style = {
            rect: { cursor: isDisabled ? 'default' : 'pointer', borderRadius: '20px', stroke: isDisabled ? '#b0bec5' : '#78909c', strokeWidth: '1px', fill: 'none', transition: 'fill 150ms ease-in' },
            text: { cursor: isDisabled ? 'default' : 'pointer', userSelect: 'none', fontSize: '1rem', fontWeight: 400, fill: isDisabled ? '#b0bec5' : '#78909c', textTransform: 'initial' }
        }

        const handleClick = () => {
            if (!isDisabled) {
                const form = document.createElement('form')
                form.setAttribute('action', url)
                form.setAttribute('method', 'POST')
                form.setAttribute('target', '_blank')
                const inputToken = document.createElement('input')
                inputToken.setAttribute('type', 'hidden')
                inputToken.setAttribute('name', 'token')
                inputToken.setAttribute('value', token)
                const inputLanguage = document.createElement('input')
                inputLanguage.setAttribute('type', 'hidden')
                inputLanguage.setAttribute('name', 'language')
                inputLanguage.setAttribute('value', i18n.language)
                const inputSubmit = document.createElement('input')
                inputSubmit.setAttribute('type', 'submit')
                form.appendChild(inputToken)
                form.appendChild(inputLanguage)
                form.appendChild(inputSubmit)
                document.body.appendChild(form)
                form.style.display = 'none'
                form.submit()
                document.body.removeChild(form)
            }
        }
        return (
            <svg style={{ ursor: isDisabled ? 'default' : 'pointer' }} x={posX} y={posY} width={buttonWidth + buttonWidth * 0.1} height={buttonHeight + buttonHeight * 0.1} onClick={handleClick}>
                <rect style={style.rect} width={buttonWidth} height={buttonHeight} x={buttonWidth * 0.05} y={buttonHeight * 0.05} rx={buttonHeight / 2} ry={buttonWidth / 2} />
                <text style={style.text} x='50%' y='54%' alignmentBaseline="middle" textAnchor='middle'>
                    {formatText(t("ouvrir"))}
                </text>
            </svg>
        )
    }

    return (
        <svg width='100%' height='100%' viewBox={`0 0 ${size} ${size}`} className='ecosystem'>
            <defs>
                <filter id="shadow">
                    <feDropShadow dx="1" dy="1" stdDeviation="2" />
                </filter>
                <filter id="grayscale">
                    <feColorMatrix type="saturate" values="0.10" />
                </filter>
                <path id='space-habitant-curve' d={`M${center + getPosX(Math.PI * 3 / 4, textCircleRadius)} ${center - getPosY(Math.PI * 3 / 4, textCircleRadius)} A${textCircleRadius} ${textCircleRadius} 0 0 1 ${center + getPosX(Math.PI / 4, textCircleRadius)} ${center - getPosY(Math.PI / 4, textCircleRadius)}`} />
                <path id='management-curve' d={`M${center + getPosX(Math.PI / 4, textCircleRadius)} ${center - getPosY(Math.PI / 4, textCircleRadius)} A${textCircleRadius} ${textCircleRadius} 0 0 1 ${center + getPosX(Math.PI * 7 / 4, textCircleRadius)} ${center - getPosY(Math.PI * 7 / 4, textCircleRadius)}`} />
                <path id='supervision-curve' d={`M${center + getPosX(Math.PI * 5 / 4, textCircleRadius2)} ${center - getPosY(Math.PI * 5 / 4, textCircleRadius2)} A${textCircleRadius2} ${textCircleRadius2} 0 0 0 ${center + getPosX(Math.PI * 7 / 4, textCircleRadius2)} ${center - getPosY(Math.PI * 7 / 4, textCircleRadius2)}`} />
                <path id='operation-curve' d={`M${center + getPosX(Math.PI * 5 / 4, textCircleRadius)} ${center - getPosY(Math.PI * 5 / 4, textCircleRadius)} A${textCircleRadius} ${textCircleRadius} 0 0 1 ${center + getPosX(Math.PI * 3 / 4, textCircleRadius)} ${center - getPosY(Math.PI * 3 / 4, textCircleRadius)}`} />
            </defs>

            <g style={{ filter: 'url(#shadow)' }}>
                {/* ESPACE HABITANT */}
                <path
                    className='ecosystem__module-path'
                    style={access && access.gd6d_platform ? {} : { fill: '#cfd8dc', stroke: '#b0bec5' }}
                    d={`
                        M${center + getPosX(Math.PI * 3 / 4, outerCircleRadius)} ${center - getPosY(Math.PI * 3 / 4, outerCircleRadius)}
                        A${outerCircleRadius} ${outerCircleRadius} 
                            0 0 1 
                            ${center + getPosX(Math.PI / 4, outerCircleRadius)} ${center - getPosY(Math.PI / 4, outerCircleRadius)}
                        L${center + getPosX(Math.PI / 4)} ${center - getPosY(Math.PI / 4)}
                        A${innerCircleRadius} ${innerCircleRadius} 
                            0 0 0 
                            ${center + getPosX(Math.PI * 3 / 4)} ${center - getPosY(Math.PI * 3 / 4)}
                        Z
                    `}
                />
                <text style={style.text}><textPath href='#space-habitant-curve' startOffset='50%'>{t("espace_habitant")}</textPath></text>
                {/* ESPACE HABITANT --> GD6D PLATFORM */}
                <path
                    className='ecosystem__module-path'
                    style={access && access.gd6d_platform ? {} : { fill: '#cfd8dc', stroke: '#b0bec5' }}
                    d={`
                        M${center + getPosX(Math.PI * 3 / 4)} ${center - getPosY(Math.PI * 3 / 4)} 
                        A${innerCircleRadius} ${innerCircleRadius}
                            0 0 1
                            ${center + getPosX(Math.PI / 4)} ${center - getPosY(Math.PI / 4)}
                        L${center} ${center}
                        Z
                    `}
                />
                <Gd6DLogoPlatform
                    width={innerCircleRadius * 0.5}
                    height={innerCircleRadius * 0.25}
                    x={center + getPosX(Math.PI / 2, ElPosRadius) - (innerCircleRadius * 0.5) / 2}
                    y={center - getPosY(Math.PI / 2, ElPosRadius) - (innerCircleRadius * 0.25) * 0.75}
                    isDisabled={access && access.gd6d_platform ? false : true}
                />
                <SVGButton
                    posX={center + getPosX(Math.PI / 2, ElPosRadius) - (buttonWidth + buttonWidth * 0.1) / 2}
                    posY={center - getPosY(Math.PI / 2, ElPosRadius) + (buttonHeight + buttonHeight * 0.1) * 0.7}
                    url={URLAPPGD6D.platform}
                    isDisabled={true}
                />



                {/* MANAGEMENT */}
                <path
                    className='ecosystem__module-path'
                    d={`
                        M${center + getPosX(Math.PI / 4, outerCircleRadius)}
                        ${center - getPosY(Math.PI / 4, outerCircleRadius)} 
                        A${outerCircleRadius} ${outerCircleRadius} 
                            0 0 1 
                            ${center + getPosX(Math.PI * 7 / 4, outerCircleRadius)} ${center - getPosY(Math.PI * 7 / 4, outerCircleRadius)}
                        L${center + getPosX(Math.PI * 7 / 4)} ${center - getPosY(Math.PI * 7 / 4)}
                        A${innerCircleRadius} ${innerCircleRadius} 
                            0 0 0
                            ${center + getPosX(Math.PI / 4)} ${center - getPosY(Math.PI / 4)}
                        Z 
                    `}
                />
                <text style={style.text}><textPath href='#management-curve' startOffset='50%'>{t("management")}</textPath></text>
                {/* MANAGEMENT --> MOBILISATION */}
                <path
                    className='ecosystem__module-path'
                    d={`
                        M${center + getPosX(Math.PI / 4)} ${center - getPosY(Math.PI / 4)} 
                        A${innerCircleRadius} ${innerCircleRadius} 
                            0 0 1 
                            ${center + getPosX(0)} ${center - getPosY(0)} 
                        L${size / 2} ${size / 2} 
                        Z
                    `}
                />
                <Gd6DLogoManagementMob
                    width={innerCircleRadius * 0.605}
                    height={innerCircleRadius * 0.3}
                    x={center + getPosX(Math.PI * 2 / 12, ElPosRadius) - (innerCircleRadius * 0.605) / 3}
                    y={center - getPosY(Math.PI * 2 / 12, ElPosRadius) - (innerCircleRadius * 0.3) / 2}
                    isDisabled={access && access.management_mobilisation ? false : true}
                />
                <SVGButton
                    posX={center + getPosX(Math.PI / 12, ElPosRadius) - (buttonWidth + buttonWidth * 0.1) / 2.5}
                    posY={center - getPosY(Math.PI / 12, ElPosRadius) - (buttonHeight + buttonHeight * 0.1) / 2}
                    url={URLAPPGD6D.manaMob}
                    isDisabled={access && access.management_mobilisation ? false : true}
                />
                {/* MANAGEMENT --> ACCOMPAGNEMENT */}
                <path
                    className='ecosystem__module-path'
                    d={`
                        M${center + getPosX(0)} ${center - getPosY(0)}
                        A${innerCircleRadius} ${innerCircleRadius} 
                            0 0 1 
                            ${center + getPosX(Math.PI * 7 / 4)} ${center - getPosY(Math.PI * 7 / 4)} 
                        L${center} ${center} 
                        Z
                    `}
                />
                <Gd6DLogoManagementAcc
                    width={innerCircleRadius * 0.8}
                    height={innerCircleRadius * 0.4}
                    x={center + getPosX(-(Math.PI / 12), ElPosRadius) - (innerCircleRadius * 0.8) / 2}
                    y={center - getPosY(-(Math.PI / 12), ElPosRadius) - (innerCircleRadius * 0.4) / 2}
                    isDisabled={access && access.management_accompagnement ? false : true}
                />
                <SVGButton
                    posX={center + getPosX(-Math.PI * 2 / 12, ElPosRadius) - (buttonWidth + buttonWidth * 0.1) / 4}
                    posY={center - getPosY(-Math.PI * 2 / 12, ElPosRadius) - (buttonHeight + buttonHeight * 0.1) / 2}
                    url={URLAPPGD6D.manaAcc}
                    isDisabled={access && access.management_accompagnement ? false : true}
                />



                {/* SUPERVISION */}
                <path
                    className='ecosystem__module-path'
                    d={`
                        M${center + getPosX(Math.PI * 7 / 4, outerCircleRadius)} ${center - getPosY(Math.PI * 7 / 4, outerCircleRadius)} 
                        A${outerCircleRadius} ${outerCircleRadius}
                            0 0 1
                            ${center + getPosX(Math.PI * 5 / 4, outerCircleRadius)} ${center - getPosY(Math.PI * 5 / 4, outerCircleRadius)}
                        L${center + getPosX(Math.PI * 5 / 4)} ${center - getPosY(Math.PI * 5 / 4)} 
                        A${innerCircleRadius} ${innerCircleRadius}
                            0 0 0 
                            ${center + getPosX(Math.PI * 7 / 4)} ${center - getPosY(Math.PI * 7 / 4)} 
                        Z
                    `}
                />
                <text style={style.text}><textPath href='#supervision-curve' startOffset='50%'>{t("supervision")}</textPath></text>
                {/* SUPERVISION --> DASHBOARD */}
                <path
                    className='ecosystem__module-path'
                    d={`
                        M${center + getPosX(Math.PI * 7 / 4)} ${center - getPosY(Math.PI * 7 / 4)} 
                        A${innerCircleRadius} ${innerCircleRadius}
                            0 0 1
                            ${center + getPosX(Math.PI * 5 / 4)} ${center - getPosY(Math.PI * 5 / 4)}
                        L${center} ${center}
                        Z
                    `}
                />
                <Gd6DLogoDashboard
                    width={innerCircleRadius * 0.6}
                    height={innerCircleRadius * 0.3}
                    x={center + getPosX(-Math.PI / 2, ElPosRadius) - (innerCircleRadius * 0.6) / 2}
                    y={center - getPosY(-Math.PI / 2, ElPosRadius) - (innerCircleRadius * 0.3) / 1.25}
                    isDisabled={access && access.supervision_dashboard ? false : true}
                />
                <SVGButton
                    posX={center + getPosX(-Math.PI / 2, ElPosRadius) - (buttonWidth + buttonWidth * 0.1) / 2}
                    posY={center - getPosY(-Math.PI / 2, ElPosRadius) + (buttonHeight + buttonHeight * 0.1) * 0.25}
                    url={URLAPPGD6D.dashboard}
                    isDisabled={access && access.supervision_dashboard ? false : true}
                />



                {/* OPERATION */}
                <path
                    className='ecosystem__module-path'
                    d={`
                        M${size / 2 + getPosX((Math.PI * 5) / 4, outerCircleRadius)} ${size / 2 - getPosY((Math.PI * 5) / 4, outerCircleRadius)} 
                        A ${outerCircleRadius} ${outerCircleRadius}
                            0 0 1 
                            ${size / 2 + getPosX((Math.PI * 3) / 4, outerCircleRadius)} ${size / 2 - getPosY((Math.PI * 3) / 4, outerCircleRadius)}
                        L${size / 2 + getPosX((Math.PI * 3) / 4)} ${size / 2 - getPosY((Math.PI * 3) / 4)}
                        A ${innerCircleRadius} ${innerCircleRadius}
                            0 0 0 
                            ${size / 2 + getPosX((Math.PI * 5) / 4)} ${size / 2 - getPosY((Math.PI * 5) / 4)} 
                        Z
                    `}
                />
                <text style={style.text}><textPath href='#operation-curve' startOffset='50%'>{t("operation")}</textPath></text>
                {/* OPERATION --> ACCOMPAGNEMENT */}
                <g  >
                    <path
                        className='ecosystem__module-path'
                        d={`
                            M${center + getPosX(Math.PI * 5 / 4)} ${center - getPosY(Math.PI * 5 / 4)} 
                            A${innerCircleRadius} ${innerCircleRadius} 
                                0 0 1 
                                ${center + getPosX(Math.PI)} ${center - getPosY(Math.PI)}
                            L${center} ${center} 
                            Z
                        `}
                    />
                    <Gd6DLogoOpeAccLogo
                        width={innerCircleRadius * 0.8}
                        height={innerCircleRadius * 0.4}
                        x={center + getPosX(Math.PI + (Math.PI / 12), ElPosRadius) - (innerCircleRadius * 0.8) / 2}
                        y={center - getPosY(Math.PI + (Math.PI / 12), ElPosRadius) - (innerCircleRadius * 0.4) / 2}
                        isDisabled={access && access.operation_accompagnement ? false : true}
                    />
                    <SVGButton
                        posX={center + getPosX(Math.PI + (Math.PI * 2 / 12), ElPosRadius) - (buttonWidth + buttonWidth * 0.1) * 0.8}
                        posY={center - getPosY(Math.PI + (Math.PI * 2 / 12), ElPosRadius) - (buttonHeight + buttonHeight * 0.1) / 2}
                        url={URLAPPGD6D.opeAcc}
                        isDisabled={access && access.operation_accompagnement ? false : true}
                    />
                </g>
                {/* OPERATION --> MOBILISATION */}
                <path
                    className='ecosystem__module-path'
                    d={`M${size / 2 + getPosX(Math.PI)} ${size / 2 - getPosY(Math.PI)} 
                        A ${innerCircleRadius} ${innerCircleRadius} 
                        0 0 1 
                        ${size / 2 + getPosX((Math.PI * 3) / 4)} ${size / 2 - getPosY((Math.PI * 3) / 4)}
                        L${size / 2} ${size / 2} Z`}
                />
                <Gd6DLogoOpeMob
                    width={innerCircleRadius * 0.605}
                    height={innerCircleRadius * 0.3}
                    x={center + getPosX(Math.PI - (Math.PI * 2 / 12), ElPosRadius) - (innerCircleRadius * 0.605) * 0.68}
                    y={center - getPosY(Math.PI - (Math.PI * 2 / 12), ElPosRadius) - (innerCircleRadius * 0.3) / 2}
                    isDisabled={access && access.operation_mobilisation ? false : true}
                />
                <SVGButton
                    posX={center + getPosX(Math.PI - (Math.PI / 12), ElPosRadius) - (buttonWidth + buttonWidth * 0.1) * 0.68}
                    posY={center - getPosY(Math.PI - (Math.PI / 12), ElPosRadius) - (buttonHeight + buttonHeight * 0.1) / 2}
                    url={URLAPPGD6D.opeMob}
                    isDisabled={access && access.operation_mobilisation ? false : true}
                />



                {/* CENTER */}
                <svg x={center - circleRadius} y={center - circleRadius} width={circleRadius * 2} height={circleRadius * 2} >
                    <defs>
                        <radialGradient id='gradient-red' cx='25%' cy='75%' fx='0%' fy='75%'>
                            <stop offset='5%' stopColor='#d50000'>
                                <animate attributeName='stop-color' values='#d50000; #ff6d00; #ffea00; #00e676; #d50000;' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                            <stop offset='100%' stopColor='rgba(213, 0, 0, 0)'>
                                <animate attributeName='stop-color' values='rgba(213, 0, 0, 0); rgba(255, 109, 0, 0); rgba(255, 234, 0, 0); rgba(0, 230, 118, 0); rgba(213, 0, 0, 0);' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                        </radialGradient>
                        <radialGradient id='gradient-orange' cx='25%' cy='25%' fx='0%' fy='0%'>
                            <stop offset='5%' stopColor='#ff6d00'>
                                <animate attributeName='stop-color' values='#ff6d00; #ffea00; #00e676; #d50000; #ff6d00;' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                            <stop offset='100%' stopColor='rgba(255, 109, 0, 0)'>
                                <animate attributeName='stop-color' values='rgba(255, 109, 0, 0), rgba(255, 234, 0, 0); rgba(0, 230, 118, 0); rgba(213, 0, 0, 0); rgba(255, 109, 0, 0);' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                        </radialGradient>
                        <radialGradient id='gradient-yellow' cx='75%' cy='25%' fx='100%' fy='0%'>
                            <stop offset='5%' stopColor='#ffea00'>
                                <animate attributeName='stop-color' values='#ffea00; #00e676; #d50000; #ff6d00; #ffea00;' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                            <stop offset='100%' stopColor='rgba(255, 234, 0, 0)'>
                                <animate attributeName='stop-color' values='rgba(255, 234, 0, 0); rgba(0, 230, 118, 0); rgba(213, 0, 0, 0); rgba(255, 109, 0, 0); rgba(255, 234, 0, 0)' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                        </radialGradient>
                        <radialGradient id='gradient-green' cx='75%' cy='75%' fx='100%' fy='100%'>
                            <stop offset='5%' stopColor='#00e676'>
                                <animate attributeName='stop-color' values='#00e676; #d50000; #ff6d00; #ffea00; #00e676; ' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                            <stop offset='100%' stopColor='rgba(0, 230, 118, 0)'>
                                <animate attributeName='stop-color' values='rgba(0, 230, 118, 0); rgba(213, 0, 0, 0); rgba(255, 109, 0, 0); rgba(0, 230, 118, 0)' dur={animDur + 's'} repeatCount='indefinite' />
                            </stop>
                        </radialGradient>

                    </defs>
                    <circle cx='50%' cy='50%' r={circleRadius} fill="url('#gradient-red')" />
                    <circle cx='50%' cy='50%' r={circleRadius} fill="url('#gradient-orange')" />
                    <circle cx='50%' cy='50%' r={circleRadius} fill="url('#gradient-yellow')" />
                    <circle cx='50%' cy='50%' r={circleRadius} fill="url('#gradient-green')" />
                    <circle cx='50%' cy='50%' r={circleRadius - circleRadius * 0.075} fill="#eceff1EC" >
                        <animate attributeName='r' values={`${circleRadius - circleRadius * 0.075}; ${circleRadius - circleRadius * 0.025}; ${circleRadius - circleRadius * 0.075};`} dur={animDur * 1.5 + 's'} repeatCount='indefinite' />
                    </circle>
                </svg>

            </g>
        </svg>
    )
}

export default Ecosystem
