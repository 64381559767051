import React, { useEffect, useReducer } from 'react'
import './Login.scss'

import i18n from "i18next"
import { availableLanguages } from '../../variables/languages'
import { VAR_LOCALSTORAGE_LANGUAGE } from '../../variables/localStorage'

import axios from 'axios'
import { connect } from 'react-redux'

import { setUserToken, setUserInfos, setUserAccess, setUserAlert, logOut } from '../../actions/userActions'
import { useTranslation } from 'react-i18next'

import LoginPaper from '../../lib/e3d-ui-kit/js/LoginPaper'
import { Gd6DLogo } from '../SvgComponent'

const loginInitialState = {
  loading: false,
  log: process.env.REACT_APP_LOGIN,
  pwd: process.env.REACT_APP_PWD,
  errorMessage: ''
}
const loginReducer = (state, action) => {
  switch (action.type) {
    case 'set_login_log':
      return {
        ...state,
        log: action.payload
      }
    case 'set_login_pwd':
      return {
        ...state,
        pwd: action.payload
      }
    case 'set_login_error':
      return {
        ...state,
        errorMessage: action.payload
      }
    case 'set_login_loading':
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state
  }
}

const Login = ({ token, setUserToken, setUserInfos, setUserAccess, setUserAlert, logOut }) => {
  const [loginState, loginDispatch] = useReducer(loginReducer, loginInitialState)
  const setLog = value => loginDispatch({ type: 'set_login_log', payload: value })
  const setPwd = value => loginDispatch({ type: 'set_login_pwd', payload: value })
  const setErrorMessage = value => loginDispatch({ type: 'set_login_error', payload: value })
  const setLoading = bool => loginDispatch({ type: 'set_login_loading', payload: bool })

  const { t } = useTranslation()

  const signalToken = axios.CancelToken.source()

  useEffect(() => {
    loadMemberWithToken && token && loadMemberWithToken(token)
    // eslint-disable-next-line
  }, [])

  const switchLang = (lang) => {
    if (lang !== i18n.language) {
      localStorage.setItem(VAR_LOCALSTORAGE_LANGUAGE, lang)
      window.location.reload()
    }
  }

  const onConnect = (login, password) => {
    try {
      const data = { login, password }
      const url = process.env.REACT_APP_URL_GD6D
      axios.post(`${url}?r=apiBiz/logMe`, data, { CancelToken: signalToken.token })
        .then(res => {
          const output = res.data
          if (output.status === 'ok') {
            const token = output.data.token
            setUserToken(token)
            setErrorMessage('')
            loadMemberWithToken(token)
          } else {
            console.warn('Problem with API : ' + output.status + '<br/>\r\n<em>' + output.error?.message + '</em>')
            setErrorMessage('error_0001') // ('Identifiant ou mot de passe incorrect)
            onDisconnect()
          }
        })
        .catch(err => {
          console.warn(err.data)
          setErrorMessage('error_0002') // ('Identifiant ou mot de passe incorrect')
          onDisconnect()
        })
    } catch (err) {
      console.warn('Erreur lors de la connexion', err)
      setErrorMessage('error_0003') // ('Erreur de communication avec la base de données')
      onDisconnect()
    }
  }

  const loadMemberWithToken = token => {
    token = token || null
    try {
      const url = process.env.REACT_APP_URL_GD6D
      axios.get(url + '?r=apiBiz/getAppAccesInfos', { headers: { 'Authorization': 'Bearer ' + token } })
        .then(res => {
          const output = res.data
          if (output.status === 'ok') {
            // Aucune équipe => Probleme
            // if( !(output.data.cfg.equipes.length > 0) ) {
            //   alert("Aucune équipe active\r\nContactez votre administrateur");
            //   onDisconnect()
            //   return;
            // }
            // RAS => Connexion
            setTimeout(() => {
              //console.log(output.data.access)
              setUserInfos(output.data.member)
              setUserAccess(output.data.access)
              setUserAlert(output.data.alert)
            }, 0)
            setLoading(false)
          } else {
            console.warn('Problem with API : ' + output.status + '<br/>\r\n<em>' + output.error?.message + '</em>')
            onDisconnect()
          }
        })
        .catch(err => {
          console.warn(err)
          onDisconnect()
        })
    } catch (err) {
      console.warn('Erreur lors de la récupération des données du membre', err);
    }
  }

  const onDisconnect = () => {
    setLoading(false)
    logOut()
  }

  // Changement du title (dans onglet de la page)
  const title = t('app_title')
  if (title !== 'app_title') {
    document.title = title
  }

  return (
    <>
      <div style={{ float: "right", color: "white", marginRight: "20px", marginTop: "10px" }}>
        {availableLanguages.map((lng, i) => {
          let curStyle = { cursor: "pointer" };
          if (lng?.code === i18n.language) {
            curStyle = { ...curStyle, fontWeight: 'bold' }
          }
          return <span key={i}>{i > 0 && <> / </>}<span style={curStyle} onClick={() => switchLang(lng?.code)}>{lng?.nom}</span></span>
        })}
      </div>
      <div className='login'>
        <LoginPaper
          logo={<Gd6DLogo height='130px' />}
          state={loginState}
          setLog={setLog}
          setPwd={setPwd}
          setErrorMessage={setErrorMessage}
          setLoading={setLoading}
          onConnect={onConnect}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserToken: token => dispatch(setUserToken(token)),
    setUserInfos: infos => dispatch(setUserInfos(infos)),
    setUserAccess: access => dispatch(setUserAccess(access)),
    setUserAlert: alert => dispatch(setUserAlert(alert)),
    logOut: () => dispatch(logOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)