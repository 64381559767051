import * as React from "react";

function SvgGd6DLogo(props) {
  return (
    <svg
      id="GD6D__logo_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 174.08 221.59"
      {...props}
    >
      <defs>
        <style>
          {
            ".GD6D__logo_svg__cls-1{fill:#dd2c00}.GD6D__logo_svg__cls-2{fill:#aeea00}.GD6D__logo_svg__cls-3{fill:#00c853}.GD6D__logo_svg__cls-4{fill:#ffd600}.GD6D__logo_svg__cls-5{fill:#64dd17}.GD6D__logo_svg__cls-6{fill:#ffab00}.GD6D__logo_svg__cls-7{fill:#263238}"
          }
        </style>
      </defs>
      <circle className="GD6D__logo_svg__cls-1" cx={73} cy={119.33} r={8.09} />
      <circle className="GD6D__logo_svg__cls-2" cx={73} cy={54.63} r={8.09} />
      <circle
        className="GD6D__logo_svg__cls-3"
        cx={101.01}
        cy={103.16}
        r={8.09}
      />
      <circle
        className="GD6D__logo_svg__cls-4"
        cx={44.98}
        cy={70.81}
        r={8.09}
      />
      <circle
        className="GD6D__logo_svg__cls-5"
        cx={101.01}
        cy={70.81}
        r={8.09}
      />
      <circle
        className="GD6D__logo_svg__cls-6"
        cx={44.98}
        cy={103.16}
        r={8.09}
      />
      <path
        className="GD6D__logo_svg__cls-2"
        d="M49 31.45a8.1 8.1 0 00-12.14 7v2.79a16.79 16.79 0 0029.08 0L49 31.46"
      />
      <path
        className="GD6D__logo_svg__cls-6"
        d="M36.9 135.52a8.09 8.09 0 0012.13 7l2.41-1.4A16.8 16.8 0 0036.89 116v19.57"
      />
      <path
        className="GD6D__logo_svg__cls-3"
        d="M133.07 94a8.08 8.08 0 003-11 7.93 7.93 0 00-3-3l-2.41-1.39a16.6 16.6 0 00-8.39 2.25 16.77 16.77 0 00-6.15 22.93L133.07 94"
      />
      <path
        className="GD6D__logo_svg__cls-4"
        d="M36.89 38.46a8.1 8.1 0 0112.14-7l2.41 1.39A16.81 16.81 0 0136.89 58V38.46"
      />
      <path
        className="GD6D__logo_svg__cls-1"
        d="M49 142.53a8.09 8.09 0 01-12.13-7v-2.79a16.8 16.8 0 0129.09 0l-17 9.78"
      />
      <path
        className="GD6D__logo_svg__cls-5"
        d="M133.07 80a7.93 7.93 0 013 3 8.08 8.08 0 01-3 11l-2.41 1.39a16.8 16.8 0 01-14.54-25.19l16.95 9.8"
      />
      <path
        className="GD6D__logo_svg__cls-7"
        d="M62.32 173.28v1.6a15.47 15.47 0 01-5 12 16.73 16.73 0 01-11.16 4.28 15.78 15.78 0 11-.09-31.56 16.28 16.28 0 0112.8 6l-5.56 4.4a8.4 8.4 0 00-7-3.51c-4.87 0-8.56 3.87-8.56 9.07a8.85 8.85 0 008.88 9 7.93 7.93 0 007.6-5.23h-7.44v-6zM66.33 160.22h8.88c5.24 0 8.47.95 11.25 3.32a14.73 14.73 0 015 11.56 16.18 16.18 0 01-4.83 12c-2.5 2.28-6.19 3.51-10.56 3.51h-9.7zm7.38 24.31h1.14c5.37 0 9.1-3.73 9.1-9.2a9.93 9.93 0 00-2.77-7 9.48 9.48 0 00-6.06-2.19h-1.41zM105.22 172.15a4.23 4.23 0 011-.14c4.46 0 8.11 4.05 8.11 8.92a10.22 10.22 0 01-10.25 10.29 9.5 9.5 0 01-9.7-9.74c0-3 1.14-6.06 3.83-10.29l7-11H113zm-4.37 9.19a3.3 3.3 0 003.41 3.42 3.53 3.53 0 003.6-3.51 3.39 3.39 0 00-3.41-3.55 3.56 3.56 0 00-3.6 3.64zM118.61 160.22h8.88c5.24 0 8.47.95 11.24 3.32a14.7 14.7 0 015 11.56 16.18 16.18 0 01-4.83 12c-2.5 2.28-6.19 3.51-10.56 3.51h-9.7zm7.39 24.31h1.13c5.38 0 9.11-3.73 9.11-9.2a9.89 9.89 0 00-2.78-7 9.43 9.43 0 00-6-2.19H126z"
      />
    </svg>
  );
}

export default SvgGd6DLogo;
