
export const formatText = s => {
  // Si texte nul
  if (!(s && s.charAt)) {
    return ''
  }
  // Format normal
  var text = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
  // Fix champs spécifiques
  const nbLoop = 10
  for (let i = 0; i < nbLoop; i++) {
    text = text.replace('gd6d', 'GD6D™')
  }
  // Fix multiple 
  for (let i = 0; i < 2 * nbLoop; i++) {
    text = text.replace('™™', '™')
  }
  // Renvoi 
  return text
}

export const extractParagraph = s => {
  // Si texte nul
  if (!(s && s.charAt)) {
    return []
  }
  // Format normal
  var text = s
  const sepPart = '<br />'
  // Uniformise les sauts de ligne
  const nbLoop = 1000
  for (let i = 0; i < nbLoop; i++) {
    text = text.replace("\r\n", sepPart)
    text = text.replace('<br>', sepPart)
    text = text.replace('<br/>', sepPart)
  }
  // Renvoi 
  return text.split(sepPart)
}
