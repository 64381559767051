const l = {}

l.languages = [
    { code: 'fr', nom: "Français" },
    { code: 'en', nom: "English" },
    { code: 'es', nom: "Español" },
]

export const availableLanguages = l.languages


export default l