import { useState, useMemo, useRef } from 'react'
import './AppContent.scss'
import { useTranslation } from 'react-i18next'
import { ArrowDropDown } from '@material-ui/icons'
import Ecosystem from '../Ecosystem/Ecosystem'
import { Gd6DLogoEcosystem } from '../SvgComponent'
import logoE3D from '../../static/logo-e3d-blc.png'
import { formatText } from '../../helpers'
import ActionLoading from '../ActionLoading/ActionLoading'

const AppContent = ({ user, logOut, goAdmin }) => {
    const [menu, setMenu] = useState(false)
    const token = useMemo(() => user.token, [user.token])
    const infos = useMemo(() => user.infos, [user.infos])
    const access = useMemo(() => user.access, [user.access])
    const alert = useMemo(() => user.alert, [user.alert])
    const menuRef = useRef()

    const { t } = useTranslation()

    const handleClick = () => {
        setMenu(!menu)
        setTimeout(() => menuRef.current.focus(), 10)
    }

    return (
        <div className='app-content'>
            <div className='app-content__header'>
                <div className='app-content__header__logo'>
                    <Gd6DLogoEcosystem height={84} />
                </div>
                <div className='app-content__header__user'>
                    <div className='app-content__header__user__infos'>
                        <div>{infos.first_name + ' ' + infos.last_name}</div>
                        {false && <div>{infos.titre}</div>}
                    </div>
                    <div className='app-content__header__user__avatar'>
                        <div dangerouslySetInnerHTML={{ __html: infos.avatar }}></div>
                    </div>
                    <div className='app-content__header__user__btn-collapse'>
                        <button onClick={handleClick}><ArrowDropDown /></button>
                        {menu &&
                            <div className='menu' tabIndex='0' ref={menuRef} onBlur={() => setMenu(false)}>
                                <div onClick={() => goAdmin()}>{formatText(t("administration"))}</div>
                                <div onClick={() => logOut()}>{formatText(t("deconnexion"))}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                alert && <ActionLoading
                    alert={alert}
                />
            }
            <div className='app-content__body'>
                <div className='app-content__body__svg'>
                    <Ecosystem token={token} access={access} />
                </div>
            </div>
            <div className='app-content__footer'>
                <span style={{ marginRight: "calc(90% - 200px)", opacity: "0.3" }}>v{process.env.REACT_APP_VERSION}</span>
                <span>{formatText(t("un_dispositif"))}</span>
                <a href='http://www.e3d-environnement.com/' target='blank'><img src={logoE3D} alt='Logo E3D-Environnement' /></a>
            </div>
        </div>
    )
}

export default AppContent
