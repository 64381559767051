import React, { useRef } from 'react'
import '../scss/LoginPaper.scss'

import { useTranslation } from 'react-i18next'
import Loader from './Loader'
import { formatText } from "../../../helpers"

const LoginPaper = (props) => {
  const {
    logo,
    state,
    setLog,
    setPwd,
    setErrorMessage,
    setLoading,
    onConnect,
    user = null
  } = props

  const {
    log,
    pwd,
    errorMessage,
    loading
  } = state

  const ref = useRef()

  const handleSubmit = e => {
    e.preventDefault()
    if (log !== '' && pwd !== '') {
      setLoading(true)
      onConnect(log, pwd)
    } else {
      setErrorMessage('Identifiant ou mot de passe incorrect')
    }
  }
  const handleChangeLog = e => setLog(e.target.value)
  const handleChangePwd = e => setPwd(e.target.value)
  const { t } = useTranslation()

  return (
    <div className='login-paper'>
      <div className='login-paper__gradient'></div>
      <div className='login-paper__main'>
        <div className='login-paper__main__logo'>
          {logo}
        </div>
        <div className='login-paper__main__content'>
          {loading &&
            <Loader />
          }
          {(!(user && user.member_id > 0) && !loading) &&
            <form
              id='login-form'
              className='login-form'
              onSubmit={handleSubmit}
            >
              <div className='login-form__input-wrapper'>
                <label htmlFor='log'>{formatText(t("identifiant"))}</label>
                <input
                  ref={ref}
                  type='text'
                  name='log'
                  value={log}
                  onChange={handleChangeLog}
                  required
                />
              </div>
              <div className='login-form__input-wrapper'>
                <label htmlFor='pwd'>{formatText(t("motDePasse"))}</label>
                <input
                  ref={ref}
                  type='password'
                  name='pwd'
                  value={pwd}
                  onChange={handleChangePwd}
                  required
                />
              </div>
              <div className={`error-message ${errorMessage && errorMessage.length > 0 ? 'error-message--visible' : 'error-message--unvisible'}`}>
                {formatText(t(errorMessage))}
              </div>
            </form>
          }
        </div>
      </div>
      <div className='login__btn-submit'>
        {(!(user && user.member_id > 0)) &&
          <input type='submit' form='login-form' value={t("connexion")} disabled={loading} />
        }
      </div>
    </div>
  )
}

export default LoginPaper
